<template>
  <section
    v-if="get_highlighted_products.length && Object.keys(get_translations)"
    class="highlighted-products"
    :class="{ 'highlighted-products--admin': admin_imported && show_mobile }"
  >
    <h3
      data-cy="selected-products"
      v-if="!is_marketplace_environment"
      class="title title__normal"
    >{{ get_translations.highlighted_products.title }}</h3>

    <div class="highlighted-products__products">
      <div v-if="show_empty_admin_preview" class="highlighted-products__demo-message">
        <p class="md-title">{{ $translate("highlighted_products.demo_message") }}</p>
      </div>
      <div
        v-for="(product, index) in get_highlighted_products"
        :key="`highlighted-products-${index}`"
        class="highlighted-products__product"
        :class="{ 'load-animation-class': product.visible }"
        v-observe-visibility="is_visible => product.visible = is_visible"
        @click="$emit('handle_click')"
      >
        <div v-if="!product.id && !product.buffer_id" class="highlighted-products__product">
          <div class="highlighted-products__image">
            <div class="element-loading" />
          </div>
          <div class="highlighted-products__bottom">
            <div class="element-loading highlighted-products__name" />
            <div class="highlighted-products__price-section">
              <p data-cy="selected-product-price" class="element-loading highlighted-products__price" />
              <p class="element-loading highlighted-products__button highlighted-products__button--disabled" />
            </div>
          </div> 
        </div>
        <router-link
          v-else
          :to="create_correct_product_url(product, is_marketplace_environment)"
          class="link"
          :aria-label="`Checkout product ${get_correct_translation_value(product, 'title', language)}`"
          @click.native="highlighted_product_clicked(product.buffer_id || product.id)"
          itemprop="category/subcategory/product"
          :event="admin_imported ? '' : 'click'"
        >
          <!------------------  Image section  ------------------>

          <div data-cy="selected-product-image" class="highlighted-products__image">
            <v-lazy-image
              :src="get_correct_first_image(product)"
              :alt="`${get_correct_translation_value(product, 'title', language)} product image`"
              itemprop="image"
            />
          </div>

          <div class="highlighted-products__bottom">
            <div data-cy="selected-product-name" class="highlighted-products__name">
              {{ get_correct_translation_value(product, 'title', language) }}
            </div>

            <!------------------  Price section  ------------------>

            <div class="highlighted-products__price-section">
              <p
                data-cy="selected-product-price"
                class="highlighted-products__price highlighted-products__price--sale"
                v-if="Number(get_product_prices(product.variants[0], true)) > Number(get_product_prices(product.variants[0]))"
              >
                <span>
                  <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
                  {{ get_product_prices(product.variants[0], true) }}
                  <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
                </span>
                <span> | </span>
                <span>
                  <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
                  {{ get_product_prices(product.variants[0]) }}
                  <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
                </span>
              </p>
              <p
                data-cy="selected-product-price"
                class="highlighted-products__price"
                v-else
              >
                <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
                {{ get_product_prices(product.variants[0]) }}
                <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
              </p>

              <p data-cy="selected-product-button" class="highlighted-products__button">
                {{ get_button_name }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import create_correct_product_url from "../../../methods/create_correct_product_url"
import { CLIENT_STORE } from "../../../constants/other"
import { ADMIN, CONFIGURATION_STORE, CONTENT_STORE } from "../../../../Admin/constants/others_constants"
import { currency_signs, default_language, lang_to_currency_mapper } from "../../../../Shared/constants/other"
import { MARKETPLACE } from "../../../../Marketplace/constants/other"
import is_currency_symbol from "../../../../Shared/methods/is_currency_symbol"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import empty_product_object from "../../../../Admin/constants/empty_objects/empty_product_object"
import { ga_event_categories } from "../../../../Shared/constants/ga_constants"
import { ARE_ANY_PRODUCTS_BEING_FETCHED } from "../../../stores/Client/constants"
import get_correct_first_image from "../../../../Shared/methods/get_correct_first_image"
import { get_ga_instance } from "../../../../../google_tracking"

export default {
  components: {
    VLazyImage
  },
  props: {
    imported_products: {
      type: Array,
      required: false,
      default: () => []
    },
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    show_mobile: {
      type: Boolean,
      required: false,
      default: false
    },
    guide_data: {
      type: Object | null,
      required: false,
      default: null
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "products",
      "translations",
      "highlighted_products",
      "selected_language"
    ]),
    ...mapState(CONFIGURATION_STORE, {
      admin_translations: "translations",
    }),
    ...mapState(CONTENT_STORE, {
      admin_highlighted_products: "highlighted_products"
    }),
    ...mapState([
      "is_marketplace_environment",
    ]),
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    ...mapState(ADMIN, ["admin_language"]),
    ...mapGetters(CLIENT_STORE, {
      still_fetching_products: ARE_ANY_PRODUCTS_BEING_FETCHED
    }),
    show_empty_admin_preview() {
      if (this.guide_data && this.guide_data.length) return false
      return this.admin_imported && !this.get_highlighted_product_ids.length
    },
    correct_translations_object() {
      return this.admin_imported ? this.admin_translations : this.translations
    },
    language() {
      return this.selected_language || Object.keys(this.correct_translations_object)[0]
    },
    get_translations() {
      const translations = this.correct_translations_object[this.language] || this.correct_translations_object
      return translations.highlighted_products ? translations : { highlighted_products: {} }
    },
    currency_symbol() {
      if (this.guide_data) return currency_signs[lang_to_currency_mapper[this.admin_language]]

      return this.is_marketplace_environment ?
        currency_signs[this.marketplace_currency] :
        (this.get_translations.currency_symbol || this.get_translations.currency_code)
    },
    get_button_name() {
      if (this.guide_data) return this.$translate("user_guide.step_6.show_product")
      if (this.is_marketplace_environment) return this.$translate("homepage.show_product")

      return this.get_translations.highlighted_products.button
    },
    get_highlighted_product_ids() {
      return this.admin_imported ? this.admin_highlighted_products : this.highlighted_products
    },
    get_highlighted_products() {
      if (this.guide_data) return this.guide_data
      if (this.loading) return [{}, {}, {}]
      if (this.is_marketplace_environment) return this.imported_products

      if (this.admin_imported && !this.get_highlighted_product_ids.length) {
        const empty_product = empty_product_object(null, null, true, [default_language], { [default_language]: this.translations })
        return [empty_product]
      }

      const found_products = (
        this.admin_imported ? this.imported_products : this.products
      ).filter(({ id, buffer_id }) => this.get_highlighted_product_ids.includes(buffer_id || id))

      if (
        this.get_highlighted_product_ids.length &&
        this.get_highlighted_product_ids.length !== found_products.length &&
        this.still_fetching_products
      ) return [{},{},{}]

      return found_products.map(product => ({ ...product, visible: false }))
    }
  },
  methods: {
    is_currency_symbol,
    get_correct_translation_value,
    create_correct_product_url,
    get_correct_first_image,
    get_product_prices(product, get_compare_price) {
      // default_language for when creating user guide product
      return get_correct_translation_value(
        product,
        get_compare_price ? "compare_at_price" : "price",
        [this.language || default_language],
        false
      )
    },
    highlighted_product_clicked(product_id) {
      get_ga_instance().then((ga) => {
        ga.track(ga_event_categories.highlighted_products, product_id)
      })
    },
  },
}
</script>

<style lang="scss">
@use "../../../../../styles/_global-constants" as *;
@use "../../../../../styles/rise_up_effect.scss";
@use "../../../../../styles/element_loading.scss";

$class: ".highlighted-products";

#{$class} {
  padding: 0;
  position: relative;

  @media (max-width: $maximum-width) {
    padding: $page-padding $half-page-padding;
  }

  h3 {
    text-align: center;
    margin-bottom: 40px;
  }

  &--admin {
    padding: $page-padding;

    #{$class} {
      &__products {
        position: relative;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        grid-column-gap: 0;
        grid-template-columns: 1fr;
      }

      &__price {
        font-size: 14px;
        padding: 5px 10px;

        &--sale {
          &:nth-child(2) {
            font-size: 12px;
          }
          &:last-child {
            font-size: 16px;
          }
        }
      }
    }
  }

  &__demo-message {
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    border-radius: $border-radius;
    background: $transparency-white-color;
    z-index: 1;

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      margin: 0;
      text-align: center;
    }
  }

  &__products {
    display: grid;
    grid-template-columns: calc(33.3% - 13.3px) calc(33.3% - 13.3px) calc(33.3% - 13.3px);
    grid-gap: 20px;
    box-sizing: border-box;

    @media (max-width: $tablet) {
      grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    }
    @media (max-width: $mobile) {
      grid-column-gap: 0;
      grid-template-columns: 1fr;
    }
  }

  $image-heigth: 300px;

  &__product {
    border: 1px solid var(--font_heavy);
    border-radius: 6px;
    box-sizing: border-box;
    transition: 250ms ease-out;
    background-color: var(--body_color);

    img {
      height: $image-heigth;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__bottom {
    padding: $page-padding;
    border-top: 1px solid $grey;
  }

  &__image {
    padding: $page-padding;

    div {
      min-height: $image-heigth;
    }
  }

  &__name {
    padding: 0 0 20px;
    border-bottom: 1px solid var(--font_heavy);
    font-size: 15px;
    letter-spacing: -0.2px;
    line-height: 22px;
    color: var(--font_heavy);
    min-height: 43px;
  }

  &__price {
    margin: 0;
    font-size: 17px;
    color: var(--font_heavy);
    text-align: left;
    min-height: 20px;

    &--sale {
      & > span {
        display: inline-block;
        vertical-align: middle;

        &:first-child {
          padding-top: 2px;
          font-size: 11px;
          text-decoration: line-through;
        }

        &:nth-child(2) {
          margin: 0 5px;
          font-size: 15px;

          @media (max-width: $tablet) {
            font-size: 12px;
          }
        }

        &:last-child {
          color: $red-color;
          font-size: 18px;

          @media (max-width: $tablet) {
            font-size: 16px;
          }
        }
      }
    }

    @media (max-width: $tablet) {
      font-size: 14px;
      padding: 5px 10px;
    }

    &-section {
      display: grid;
      padding: 20px 0 0;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      min-height: 58px;
      column-gap: 10px;
    }
  }

  &__button {
    padding: 10px 20px;
    margin: 0;
    color: var(--background_heavy);
    vertical-align: middle;
    border: 1px solid var(--background_heavy);
    border-radius: 3px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    -webkit-transition: 250ms ease-out;
    transition: 250ms ease-out;
    min-height: 38px;

    &:hover {
      color: var(--background_light);
      background-color: var(--background_heavy);
      border-color: var(--background_heavy);
    }

    &--disabled {
      &:hover {
        color: var(--background_heavy);
        background-color: var(--background_light);
      }
    }
  }
}
</style>
